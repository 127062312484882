// eslint-disable-next-line no-restricted-imports
import {fire, on} from 'delegated-events'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
import type {PrimerMultiInputElement} from '@primer/view-components/lib/primer/forms/primer_multi_input'
import safeStorage from '@github-ui/safe-storage'
const {getItem, setItem} = safeStorage('localStorage', {ttl: 60 * 10 * 1000, throwQuotaErrorsOnSet: false})

const USA = 'US'
const CANADA = 'CA'

on('click', '.js-edit-user-personal-profile', handleEditAccountScreeningProfile)
on('click', '.js-cancel-billing-info', handleCancelBillingInfo)
on('click', '.js-view-user-personal-profile', handleViewAccountScreeningProfile)
on('click', '.js-edit-paypal-method', handleEditPayPalPaymentMethod)
on('click', '.js-enter-new-card', handleEditCreditCardPaymentMethod)
on('click', '.js-edit-payment-method-btn', handleEditPaymentMethod)
on('click', '.js-cancel-edit-payment-method-btn', handleCancelEditPaymentMethod)
on('click', '.js-sponsors-cancel-edit-payment-method-btn', handleSponsorsCancelEditPaymentMethod)
on('click', '.js-edit-linked-billing-info', handleLinkedBillingInfoEdit)
on('click', '.js-cancel-edit-linked-billing-info', handleCancelOfLinkedBillingInfoEdit)
on('click', '.js-billing-settings-billing-information-cancel-button', handleCancelOfLinkedBillingInfoEdit)
on('click', '.js-add-billing-information-btn', handleAddBillingInformation)
on('click', '.js-cancel-submit-personal-profile-btn', handleCancelAddBillingInformation)
on('click', '.js-dismiss-flash-message', handleDismissMessage)
on('click', '.js-sign-ctos-btn', handleSignCTOS)
on('click', '.js-cancel-sign-ctos', handleCancelSignCTOS)
on('change', '.js-allow-billing-info-link', handleToggleLinkingProfileInformation)
on('click', '.js-settings-cancel-billing-info-linkage', handleCancelBillingInfoLink)
on('click', '.js-show-individual-billing-info-inputs', function () {
  showIndividualBillingInformationInputs('')
})
on('click', '.js-show-business-billing-info-inputs', showBusinessBillingInformationInputs)

function setupCountryFields(countrySelect: HTMLSelectElement) {
  const country = countrySelect.options[countrySelect.selectedIndex]?.value
  const region: PrimerMultiInputElement | null = document.querySelector(
    '.js-name-address-collection primer-multi-input[data-name=region]',
  )

  if (region) {
    if (country === USA) {
      region.activateField('us_states')
    } else if (country === CANADA) {
      region.activateField('ca_provinces')
    } else {
      region.activateField('region')
    }
  }
}

observe('.js-name-address-select-country', {
  constructor: HTMLSelectElement,
  initialize(countrySelect) {
    setupCountryFields(countrySelect)
    if (countrySelect.form) fire(countrySelect.form, 'change')
    countrySelect.addEventListener('change', function () {
      setupCountryFields(countrySelect)
    })
  },
})

observe('.js-allow-billing-info-link', {
  constructor: HTMLInputElement,
  initialize(input) {
    input.addEventListener('change', handleToggleLinkingProfileInformation)
    input.dispatchEvent(new Event('change'))
  },
})

observe('.js-sponsors-handle-cancel-edit-payment .pay-by-credit-card .js-zuora-existing-card', {
  constructor: HTMLElement,
  add() {
    sponsorsHidePaymentMethod()

    const paymentMethodContainer = document.querySelector<HTMLElement>('.payment-methods')!
    paymentMethodContainer.classList.remove('js-sponsors-handle-cancel-edit-payment')
  },
})

function hideProfileSummaryContainer() {
  const profileSummaryContainer = document.querySelector<HTMLElement>('.js-user-personal-profile-summary')
  if (profileSummaryContainer) {
    profileSummaryContainer.hidden = true
  }
}

function showProfileSummaryContainer() {
  const profileSummaryContainer = document.querySelector<HTMLElement>('.js-user-personal-profile-summary')
  if (profileSummaryContainer) {
    profileSummaryContainer.hidden = false
  }
}

function handleCancelBillingInfoLink(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const linkingProfileCheckbox = document.querySelector<HTMLInputElement>('.js-allow-billing-info-link')

  if (!linkingProfileCheckbox) return

  linkingProfileCheckbox.onchange = handleToggleLinkingProfileInformation
  linkingProfileCheckbox.checked = false
  linkingProfileCheckbox.disabled = false
  linkingProfileCheckbox.dispatchEvent(new Event('change'))
}

function handleToggleLinkingProfileInformation(event: Event) {
  if (!(event.currentTarget instanceof HTMLInputElement)) return

  const linkingProfileCheckbox = event.currentTarget
  const linkingProfileSummaryContainer = document.querySelector<HTMLElement>('.js-billing-information-linking-summary')

  if (!linkingProfileSummaryContainer) return

  hidePaymentMethodsContainerContents('js-has-valid-payment-method')
  if (linkingProfileCheckbox.checked) {
    linkingProfileSummaryContainer.hidden = false
  } else {
    linkingProfileSummaryContainer.hidden = true
  }
}

function hideCancelEditBillingInfoButton() {
  const cancelButton = document.querySelector<HTMLElement>('.js-cancel-billing-info')
  if (cancelButton) {
    cancelButton.hidden = true
  }
}

function showCancelEditBillingInfoButton() {
  const cancelButton = document.querySelector<HTMLElement>('.js-cancel-billing-info')
  if (cancelButton) {
    cancelButton.hidden = false
  }
}

function handleCancelOfLinkedBillingInfoEdit(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const editProfileButtonContainer = document.querySelector<HTMLElement>('.js-edit-user-personal-profile-wrapper')
  if (editProfileButtonContainer) {
    editProfileButtonContainer.hidden = false
  }

  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection-wrapper')
  if (formContainer) {
    formContainer.hidden = true
  }

  const avatarContainer = document.querySelector<HTMLElement>('.js-billing-info-avatar-wrapper')
  if (avatarContainer) {
    avatarContainer.hidden = false
  }
  showProfileSummaryContainer()

  showPaymentMethodsContainerContents()
}

function handleLinkedBillingInfoEdit(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const editProfileButtonContainer = document.querySelector<HTMLElement>('.js-edit-user-personal-profile-wrapper')
  if (editProfileButtonContainer) {
    editProfileButtonContainer.hidden = true
  }

  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection-wrapper')
  if (formContainer) {
    formContainer.hidden = false
  }

  const avatarContainer = document.querySelector<HTMLElement>('.js-billing-info-avatar-wrapper')
  if (avatarContainer) {
    avatarContainer.hidden = true
  }

  hideProfileSummaryContainer()

  hidePaymentMethodsContainerContents()
}

function handleEditAccountScreeningProfile(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const editProfileButtonContainer = document.querySelector<HTMLElement>('.js-edit-user-personal-profile-wrapper')
  if (editProfileButtonContainer) {
    editProfileButtonContainer.hidden = true
  }

  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection-wrapper')
  if (formContainer) {
    formContainer.hidden = false

    const firstNameInput = formContainer.querySelector<HTMLInputElement>(
      'input[name="account_screening_profile[first_name]"]',
    )
    const entityNameInput = formContainer.querySelector<HTMLInputElement>(
      'input[name="account_screening_profile[entity_name]"]',
    )

    if (firstNameInput !== null) {
      firstNameInput.focus()
    } else if (entityNameInput !== null) {
      entityNameInput.focus()
    }
  }

  const profileSummaries = document.querySelector<HTMLElement>('.js-profile-summaries')
  if (profileSummaries) {
    profileSummaries.hidden = true
  }

  hideProfileSummaryContainer()

  showCancelEditBillingInfoButton()

  const upgradeCTAContainer = document.querySelector<HTMLElement>('.js-awaiting-payment')
  if (upgradeCTAContainer) {
    const upgradeCTAIsHidden = upgradeCTAContainer.hasAttribute('hidden')
    if (!upgradeCTAIsHidden) upgradeCTAContainer.hidden = true
  }

  const sponsorsFormContainer = document.querySelector<HTMLElement>('.js-sponsors-form')
  if (sponsorsFormContainer) {
    sponsorsFormContainer.hidden = true
  }

  const marketplaceFormContainer = document.querySelector<HTMLElement>('.js-marketplace-form-wrapper')
  if (marketplaceFormContainer) {
    marketplaceFormContainer.hidden = true
  }

  const sdnScreeningErrorWrapper = document.querySelector<HTMLElement>('.js-sdn-screening-error-wrapper')
  if (sdnScreeningErrorWrapper) {
    sdnScreeningErrorWrapper.hidden = true
  }

  hidePaymentMethodsContainer()

  const couponsFormContainer = document.querySelector<HTMLElement>('.js-coupons-form-wrapper')
  if (couponsFormContainer) {
    couponsFormContainer.hidden = true
  }

  const cancelPaymentFormButton = document.querySelector<HTMLElement>('.js-sponsors-cancel-edit-payment-method-btn')
  if (cancelPaymentFormButton && !cancelPaymentFormButton.hidden) {
    cancelPaymentFormButton.click()
  }

  const orgPaymentMethodContainer = document.querySelector<HTMLElement>('.js-org-payment-method-wrapper')
  if (orgPaymentMethodContainer) {
    orgPaymentMethodContainer.hidden = true
  }

  const billing = document.querySelector('.js-billing-section.js-data-collection-org-signup')
  if (billing) billing.classList.toggle('has-removed-contents')

  // Hide the page submit button so we only have the one on the form
  const llama2SubmitWrapper = document.querySelector<HTMLElement>('.js-llama2-submit-wrapper')
  if (llama2SubmitWrapper) llama2SubmitWrapper.hidden = true
}

function handleCancelBillingInfo(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  hideCancelEditBillingInfoButton()

  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection-wrapper')!
  formContainer.hidden = true

  showProfileSummaryContainer()

  const editProfileButtonContainer = document.querySelector<HTMLElement>('.js-edit-user-personal-profile-wrapper')!
  editProfileButtonContainer.hidden = false

  showPaymentMethodsContainer()

  const sponsorsFormContainer = document.querySelector<HTMLElement>('.js-sponsors-form')
  if (sponsorsFormContainer) {
    sponsorsFormContainer.hidden = false
  }

  const marketplaceFormContainer = document.querySelector<HTMLElement>('.js-marketplace-form-wrapper')
  if (marketplaceFormContainer) {
    marketplaceFormContainer.hidden = false
  }

  // Focus needs to happen after virtual items have been updated, otherwise the focused element
  // may mutate later and leave us at the wrong item.
  setTimeout(function () {
    const editProfileButton = editProfileButtonContainer.querySelector<HTMLElement>(
      'button.js-edit-user-personal-profile',
    )!
    editProfileButton.focus()
  }, 0)
}

function handleViewAccountScreeningProfile() {
  fetch('/account/user_profile_view?form_loaded_from=SPONSORABLE', {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  })
}

function handleEditPayPalPaymentMethod(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const upgradeCTAContainer = document.querySelector<HTMLElement>('.js-awaiting-payment')
  if (upgradeCTAContainer) {
    upgradeCTAContainer.hidden = true
  }

  const sponsorsFormContainer = document.querySelector<HTMLElement>('.js-sponsors-form')
  if (sponsorsFormContainer) {
    sponsorsFormContainer.hidden = true
  }

  const marketplaceFormContainer = document.querySelector<HTMLElement>('.js-marketplace-form-wrapper')
  if (marketplaceFormContainer) {
    marketplaceFormContainer.hidden = true
  }

  const paymentMethodsRadioContainer = document.querySelector<HTMLElement>('.js-payment-methods-radio')!
  paymentMethodsRadioContainer.hidden = false

  const billingPayWithContainer = document.querySelector<HTMLElement>('.js-billing-pay-with')
  if (billingPayWithContainer) {
    billingPayWithContainer.hidden = false
  }

  const paypalSummaryContainer = document.querySelector<HTMLElement>('.js-existing-paypal-summary')!
  paypalSummaryContainer.hidden = true

  const paypalDetailsContainer = document.querySelector<HTMLElement>('.js-paypal-method-details')!
  if (paypalDetailsContainer) {
    paypalDetailsContainer.hidden = false
  }

  const paypalFormContainer = document.querySelector<HTMLElement>('.js-paypal-payment-method-form')!
  paypalFormContainer.hidden = false
}

function handleEditCreditCardPaymentMethod(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const upgradeCTAContainer = document.querySelector<HTMLElement>('.js-awaiting-payment')
  if (upgradeCTAContainer) {
    upgradeCTAContainer.hidden = true
  }

  const sponsorsFormContainer = document.querySelector<HTMLElement>('.js-sponsors-form')
  if (sponsorsFormContainer) {
    sponsorsFormContainer.hidden = true
  }

  const marketplaceFormContainer = document.querySelector<HTMLElement>('.js-marketplace-form-wrapper')
  if (marketplaceFormContainer) {
    marketplaceFormContainer.hidden = true
  }

  const billingPayWithContainer = document.querySelector<HTMLElement>('.js-billing-pay-with')
  if (billingPayWithContainer) {
    billingPayWithContainer.hidden = false
  }

  const paymentMethodsRadioContainer = document.querySelector<HTMLElement>('.js-payment-methods-radio')
  if (paymentMethodsRadioContainer) {
    paymentMethodsRadioContainer.hidden = false
  }

  const cancelButton = document.querySelector<HTMLElement>('.js-sponsors-cancel-edit-payment-method-btn')
  if (cancelButton) {
    cancelButton.hidden = false
  }
}

function handleEditPaymentMethod(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  const updateBillingInformationBanner = document.querySelector<HTMLElement>('.js-update-billing-information-banner')!
  const updateBillingInfoBannerWrapper = document.querySelector<HTMLElement>('#update-billing-info-banner')
  const billingInfoLinkingWrapper = document.querySelector<HTMLElement>('.js-billing-info-linking-wrapper')
  const billingSummaryContainer = document.querySelector<HTMLElement>('.js-billing-information-summary')
  const editButton = document.querySelector<HTMLElement>('.js-edit-payment-method-btn')!
  const skippable = editButton.classList.contains('js-skip-billing-info')
  if (!billingInfoLinkingWrapper && !billingSummaryContainer && !skippable) {
    updateBillingInformationBanner.hidden = false
    updateBillingInfoBannerWrapper?.focus()
    addBillingInformationHelper()
  } else {
    updateBillingInformationBanner.hidden = true
    const paymentSummaryContainer = document.querySelector<HTMLElement>('.js-payment-summary-wrapper')!
    paymentSummaryContainer.hidden = true
    const formContainer = document.querySelector<HTMLElement>('.js-payment-method-section-wrapper')!
    formContainer.hidden = false
    editButton.hidden = true
    const iframe = document.querySelector<HTMLElement>('#z_hppm_iframe')

    if (iframe != null) {
      iframe.setAttribute('height', '100%')
      iframe.setAttribute('width', '100%')
    }
    window.dispatchEvent(new Event('resize'))
  }
}

function handleCancelEditPaymentMethod(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  const paymentSummaryContainer = document.querySelector<HTMLElement>('.js-payment-summary-wrapper')!
  paymentSummaryContainer.hidden = false
  const formContainer = document.querySelector<HTMLElement>('.js-payment-method-section-wrapper')!
  formContainer.hidden = true
  const editButton = document.querySelector<HTMLElement>('.js-edit-payment-method-btn')!
  editButton.hidden = false
}

function handleSponsorsCancelEditPaymentMethod(event: Event) {
  if (!(event.currentTarget instanceof Element)) return

  const editButton = document.querySelector('.js-sponsors-payment-method-update-confirmation-dialog')
  editButton?.classList.remove('has-removed-contents')

  const paypalFormContainer = document.querySelector<HTMLElement>('.js-paypal-payment-method-form')!

  if (paypalFormContainer == null) {
    sponsorsHidePaymentMethod()
  } else {
    const paymentMethodContainer = document.querySelector<HTMLElement>('.payment-methods')!
    paymentMethodContainer.classList.add('js-sponsors-handle-cancel-edit-payment')

    const creditCardButton = document.querySelector<HTMLInputElement>('#pay-by-credit-card')!
    creditCardButton.click()
  }
}

// Hide payment method and show Zuora card on Sponsorship checkout
function sponsorsHidePaymentMethod() {
  const billingSection = document.querySelector<HTMLElement>('.js-billing-section')
  if (billingSection) billingSection.hidden = true

  const billingPayWithContainer = document.querySelector<HTMLElement>('.js-billing-pay-with')
  if (billingPayWithContainer) billingPayWithContainer.hidden = true

  const paymentMethodsRadioContainer = document.querySelector<HTMLElement>('.js-payment-methods-radio')
  if (paymentMethodsRadioContainer) paymentMethodsRadioContainer.hidden = true

  const cancelButton = document.querySelector<HTMLElement>('.js-sponsors-cancel-edit-payment-method-btn')
  if (cancelButton) cancelButton.hidden = true

  const sponsorsFormContainer = document.querySelector<HTMLElement>('.js-sponsors-form')
  if (sponsorsFormContainer) sponsorsFormContainer.hidden = false

  const zuoraCard = document.querySelector<HTMLElement>('.js-zuora-existing-card')
  /* eslint-disable-next-line github/no-d-none */
  if (zuoraCard) zuoraCard.classList.remove('d-none')
}

function handleAddBillingInformation(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  addBillingInformationHelper()
}

function addBillingInformationHelper() {
  const billingSummaryContainer = document.querySelector<HTMLElement>('.js-billing-information-summary-wrapper')
  if (billingSummaryContainer) {
    billingSummaryContainer.hidden = true
  }
  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection-wrapper')
  if (formContainer) {
    formContainer.hidden = false
  }
  const addInfoButton = document.querySelector<HTMLElement>('.js-add-billing-information-btn')
  if (addInfoButton) {
    addInfoButton.hidden = true
  }
  const noBillingInformationMessage = document.querySelector<HTMLElement>('.js-no-billing-information-message')
  if (noBillingInformationMessage) {
    noBillingInformationMessage.hidden = true
  }
  const orgPaymentMethodContainer = document.querySelector<HTMLElement>('.js-org-payment-method-wrapper')
  if (orgPaymentMethodContainer) {
    orgPaymentMethodContainer.hidden = true
  }
}

function handleCancelAddBillingInformation(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  const billingSummaryContainer = document.querySelector<HTMLElement>('.js-billing-information-summary-wrapper')
  if (billingSummaryContainer) {
    billingSummaryContainer.hidden = false
  }
  const formContainer = document.querySelector<HTMLElement>('.js-name-address-collection-wrapper')
  if (formContainer) {
    formContainer.hidden = true
  }
  const addInfoButton = document.querySelector<HTMLElement>('.js-add-billing-information-btn')
  if (addInfoButton) {
    addInfoButton.hidden = false
  }
  const noBillingInformationMessage = document.querySelector<HTMLElement>('.js-no-billing-information-message')
  if (noBillingInformationMessage) {
    noBillingInformationMessage.hidden = false
  }
}

function handleDismissMessage(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  const updateBillingInformationBanner = document.querySelector<HTMLElement>('.js-update-billing-information-banner')!
  updateBillingInformationBanner.hidden = true
}

function paymentMethodsContainer(excludeClass = '') {
  let container
  if (excludeClass) {
    container = document.querySelector<HTMLElement>(`.js-payment-methods-wrapper:not(.${excludeClass})`)
  } else {
    container = document.querySelector<HTMLElement>('.js-payment-methods-wrapper')
  }

  return container
}

function hidePaymentMethodsContainerContents(excludeClass = '') {
  const container = paymentMethodsContainer(excludeClass)
  if (container) {
    container.classList.add('has-removed-contents')
  }
}

function hidePaymentMethodsContainer() {
  const container = paymentMethodsContainer()
  if (container) {
    container.hidden = true
  }
}

function showPaymentMethodsContainer() {
  const container = paymentMethodsContainer()
  if (container) {
    container.hidden = false
  }
}

function showPaymentMethodsContainerContents() {
  const container = paymentMethodsContainer()
  if (container) {
    container.classList.remove('has-removed-contents')
  }
}

function handleSignCTOS(event: Event) {
  const currentTarget = event.currentTarget as HTMLElement
  if (!currentTarget) return
  currentTarget.hidden = true

  const billingInfoContainer = document.querySelector<HTMLElement>('.js-tos-name-address-form')
  if (billingInfoContainer) {
    billingInfoContainer.hidden = false
  }
}

function handleCancelSignCTOS(event: Event) {
  if (!(event.currentTarget instanceof Element)) return
  const billingInfoContainer = document.querySelector<HTMLElement>('.js-tos-name-address-form')
  if (billingInfoContainer) {
    billingInfoContainer.hidden = true
  }

  const signCTOSButton = document.querySelector<HTMLElement>('.js-sign-ctos-btn')
  if (signCTOSButton) {
    signCTOSButton.hidden = false
  }
}

function showIndividualBillingInformationInputs(excludePaymentMethodContainerClass = '') {
  individualInfoInputsWrapper().classList.remove('has-removed-contents')
  businessInfoInputsWrapper().classList.add('has-removed-contents')
  hidePaymentMethodsContainerContents(excludePaymentMethodContainerClass)
  hideTradeControlsNotice()
  setItem('plan_upgrade_billing_info_type', 'individual')
}

function showBusinessBillingInformationInputs() {
  individualInfoInputsWrapper().classList.add('has-removed-contents')
  businessInfoInputsWrapper().classList.remove('has-removed-contents')
  hidePaymentMethodsContainerContents()
  hideTradeControlsNotice()
  setItem('plan_upgrade_billing_info_type', 'business')
}

observe('.js-show-individual-billing-info-inputs', {
  constructor: HTMLInputElement,
  initialize(input) {
    const billingInfoType = getItem('plan_upgrade_billing_info_type')
    if (billingInfoType === 'individual' || !billingInfoType) {
      input.checked = true
      input.dispatchEvent(new Event('change'))
      showIndividualBillingInformationInputs('js-has-valid-payment-method')
    }
  },
})

observe('.js-show-business-billing-info-inputs', {
  constructor: HTMLInputElement,
  initialize(input) {
    if (getItem('plan_upgrade_billing_info_type') === 'business') {
      input.checked = true
      input.dispatchEvent(new Event('change'))
      showBusinessBillingInformationInputs()
    }
  },
})

function hideTradeControlsNotice() {
  const tradeControlsNoticeContainer = document.querySelector<HTMLElement>('.js-trade-controls-notice-container')
  if (tradeControlsNoticeContainer) {
    tradeControlsNoticeContainer.hidden = true
  }
}

function businessInfoInputsWrapper() {
  return document.querySelector<HTMLElement>('.js-business-details-inputs-wrapper')!
}

function individualInfoInputsWrapper() {
  return document.querySelector<HTMLElement>('.js-billing-info-linking-wrapper')!
}

observe('#submit_personal_profile', {
  constructor: HTMLInputElement,
  initialize(element) {
    element.addEventListener('focus', () => {
      // The following code iterates over form inputs with names that include
      // 'account_screening_profile', and checks for validation errors such as empty fields,
      // presence of special characters, and for the 'first_name' and 'last_name' fields specifically,
      // checks for a minimum length of 2 characters, then accordingly sets
      // the 'invalid' attribute and visibility of associated error messages for each input.
      const inputs = document.querySelectorAll<HTMLInputElement | HTMLSelectElement>('input, select')
      for (const input of inputs) {
        if (input.name.includes('account_screening_profile') || input.name.includes('organization')) {
          let error = null
          const inputNameMatch = input.name.match(/\[(.*?)\]/)
          const inputName = inputNameMatch ? inputNameMatch[1] : ''
          if (input.value.trim() === '' && input.getAttribute('aria-required') === 'true') {
            error = "can't be blank"
          } else if (
            !['address1', 'address2', 'postal_code', 'billing_email'].includes(inputName!) &&
            !/^[a-zA-Z0-9\s]*$/.test(input.value)
          ) {
            error = 'should not contain special characters'
          } else if (['first_name', 'last_name'].includes(inputName!) && input.value.length < 2) {
            error = 'is too short'
          }

          if (error) {
            let inputLabel = ''
            if (inputName) {
              if (inputName === 'address1') {
                inputLabel = 'Address'
              } else {
                const splitName = inputName.split('_')
                const firstWord = splitName[0]
                if (firstWord) {
                  inputLabel = `${firstWord.charAt(0).toUpperCase() + firstWord.slice(1)} ${splitName
                    .slice(1)
                    .join(' ')}`
                }
              }
            }

            input.setAttribute('aria-invalid', 'true')
            input.setAttribute('invalid', 'true')
            input.setAttribute('autofocus', 'true')

            const errorDivId = input.getAttribute('aria-describedby')
            if (errorDivId) {
              const errorDiv = document.getElementById(errorDivId)
              if (errorDiv) {
                errorDiv.removeAttribute('hidden')
                const spans = errorDiv.getElementsByTagName('span')
                const errorSpan = errorDiv.getElementsByTagName('span')[spans.length - 1]
                if (errorSpan) {
                  errorSpan.textContent = `${inputLabel} ${error}.`
                  errorSpan.removeAttribute('hidden')
                }
              }
            }
          } else {
            input.removeAttribute('aria-invalid')
            input.removeAttribute('invalid')
            input.removeAttribute('autofocus')
            const errorDivId = input.getAttribute('aria-describedby')
            if (errorDivId) {
              const errorDiv = document.getElementById(errorDivId)
              if (errorDiv) {
                errorDiv.setAttribute('hidden', 'true')
              }
            }
          }
        }
      }
    })
  },
})
